<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { ButtonVariant, SCButton } from '@nsftx/seven-components'
import router from '@/router'
const buttonVariant = computed(
  () => (isActive: boolean) => (isActive ? ButtonVariant.Brand : ButtonVariant.Secondary)
)
const openTermsAndConditions = (isActive: boolean) => {
  if (isActive) router.back()
  else router.push({ name: 'termsAndConditions' })
}
</script>

<template>
  <div class="sc-ml-1">
    <RouterLink v-slot="{ isActive }" to="/terms-and-conditions" custom>
      <SCButton
        width="100%"
        class="sc-px-5 sc-my-1"
        data-cy="TermsAndConditionFooterButton"
        :variant="buttonVariant(isActive)"
        height="60px"
        icon="eighteen"
        @click="openTermsAndConditions(isActive)"
      />
    </RouterLink>
  </div>
</template>
