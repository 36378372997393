<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { ButtonVariant, SCButton } from '@nsftx/seven-components'
import router from '@/router'

const buttonVariant = computed(
  () => (isActive: boolean) => (isActive ? ButtonVariant.Brand : ButtonVariant.Secondary)
)

const openHelp = (isActive: boolean) => {
  if (isActive) router.back()
  else router.push({ name: 'help' })
}
</script>

<template>
  <div class="sc-ml-1" data-cy="helpButtonFooter">
    <RouterLink v-slot="{ isActive }" to="/help" custom>
      <SCButton
        width="100%"
        class="sc-px-5 sc-my-1"
        :variant="buttonVariant(isActive)"
        height="60px"
        icon="question"
        @click="openHelp(isActive)"
      />
    </RouterLink>
  </div>
</template>
