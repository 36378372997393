import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/ui/views/HomeView.vue'
import TermsAndConditionsView from '@/ui/views/TermsAndConditionsView.vue'
import HelpView from '@/ui/views/HelpView.vue'

const LobbyItemContent = () =>
  import(
    /* webpackChunkName: "lobby_item_content" */
    '@/ui/components/lobby/STLobbyItemContent.vue'
  )

const PrintTest = () =>
  import(
    /* webpackChunkName: "print_test" */
    '@/ui/views/PrintTestView.vue'
  )

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/ui/views/AboutView.vue')
    },
    {
      path: '/lobby/:id',
      name: 'LobbyItemContent',
      component: LobbyItemContent
    },
    {
      path: '/help',
      name: 'help',
      component: HelpView
    },
    {
      path: '/print-test',
      name: 'printTest',
      component: PrintTest
    },
    {
      path: '/terms-and-conditions',
      name: 'termsAndConditions',
      component: TermsAndConditionsView
    }
  ]
})

export default router
